<template>
  <section v-if="needLogin" class="login hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <!-- <div class="brand-content">
            <div class="title has-text-grey-darker">Selamat Datang di E-Logbook</div>
            <div class="subtitle is-hidden-touch has-text-grey-darker">Pendidikan Profesi Dokter Hewan</div>
            <div class="subtitle is-hidden-touch has-text-grey-darker">Fakultas Kedokteran UNPAD</div>
          </div> -->
          <!-- <div class="box"> -->
            <!-- <figure class="avatar">
              <img src="../assets/img/login-auth.png" />
            </figure> -->

            <div class="title has-text-grey-darker">E-Logbook PPDH FK UNPAD</div>
            <!-- <div class="subtext has-text-grey-darker">Pendidikan Profesi Dokter Hewan</div>
            <div class="subtext has-text-grey-darker">Fakultas Kedokteran UNPAD</div> -->
            <img id="login_pic" src="../assets/img/login_pic.png" />

            <template v-if="errorMap.detail">
              <b-field class="field-spacer"></b-field>
              <p class="help is-danger">{{ errorMap.detail }}</p>
              <b-field class="field-spacer"></b-field>
            </template>

            <form @submit.prevent="login">
              <b-field :type="{'is-danger': errorMap.username}">
                <b-input
                  ref="usernameInput"
                  placeholder="Email"
                  iconPack="fas"
                  icon="user"
                  size="is-large"
                  v-model="auth.username"
                  @input="validateInput('username')"
                  required
                ></b-input>
              </b-field>
              <b-field :type="{'is-danger': errorMap.password}">
                <b-input
                  ref="passwordInput"
                  type="password"
                  placeholder="Sandi"
                  icon-pack="fas"
                  icon="lock"
                  size="is-large"
                  v-model="auth.password"
                  @input="validateInput('password')"
                  password-reveal
                  required
                ></b-input>
              </b-field>
              <div class="field has-text-grey-darker">
                <router-link
                  :to="{ name: 'request-reset-password' }"
                >Reset Sandi</router-link>
              </div>
              <div class="control">
                <button
                  class="button is-hover is-fullwidth is-large is-primary has-text-weight-normal"
                  type="submit"
                  :disabled="!isValid"
                >Masuk</button>
              </div>
            </form>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AuthModel from "../models/authModel.js";

export default {
  name: "Login",
  data() {
    this.authModelMdl = new AuthModel();
    return this.authModelMdl.getObservables();
  },
  computed: {
    isValid() {
      return this.validity.edited && this.validity.validated;
    }
  },
  methods: {
    validateInput(field) {
      this.authModelMdl.validate(field);
    },
    goToNextURL() {
      let nextUrl = this.$route.query.next || "/";
      this.$router.replace(nextUrl);
    },
    login() {
      this.authModelMdl.login().then(this.goToNextURL);
    },
    goToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    setFocus() {
      this.$nextTick(() => {
        // focus ke username input ketika render berikutnya
        this.$refs.usernameInput.focus();
      });
    }
  },
  watch: {
    auth: {
      // auth berubah, update edited
      handler() {
        if (!this.authModelMdl.getEdited()) {
          this.authModelMdl.clearNonFieldErrors();
          this.authModelMdl.setEdited(true);
        }
      },
      deep: true
    }
  },
  created() {
    this.authModelMdl.fetchUser(this.goToDashboard, this.setFocus);
  }
};
</script>

<style scoped lang="scss">

.title {
  font-size: 18pt;
}
@media screen and (max-width: 1087px) {
    .title {
      font-size: 15pt;
    }
    #login_pic {
      width: 200px;
    }

//   .subtitle {
//     display: none;
//   }
}

.box {
  margin-top: 5rem;
  font-size: 14px;
  font-weight: 300;
  /* digunakan untuk keperluan b-loading yang tidak fullscreen */
  position: relative;
}

.title:not(:last-child) {
  margin-bottom: 5px;
}


.hero {
    background-color: #fff !important;
}

// .avatar {
//   margin-top: -70px;
//   padding-bottom: 20px;
// }

// .avatar img {
//   padding: 5px;
//   background: #fff;
//   border-radius: 50%;
//   -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
//     0 0 0 1px rgba(10, 10, 10, 0.1);
//   box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
// }

#login_pic {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;

  // .subtitle {
  //   padding-top: 1rem;
  // }
}

::v-deep .is-right {
  color: $ppds-blue !important;
}

::v-deep form p.help {
  display: none;
}
</style>
